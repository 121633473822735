import React, { useState } from "react";
import { useForm } from "react-hook-form";
import logo from "../img/logo.png";
import "../css/home.css";
import axios from "axios";
import { Spin } from "antd";
import { notification } from "antd";
import "react-toastify/dist/ReactToastify.css";

function Home() {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [info, setInfo] = useState({});
  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isForeignPerson, setIsForeignPerson] = useState(false);
  const [result, setResult] = useState("");

  // const examURL = "https://backimes.culture.az/api/exam-infos";
  const resultUrl = "https://backimes.culture.az/api/teacher-exam-results";

  const handleCheckboxChange = () => {
    setIsForeignPerson(!isForeignPerson);
    if (!isForeignPerson) {
      setValue("seriyaNo", "MYİ");
    } else {
      setValue("seriyaNo", "AA");
    }
  };


  const onSubmit = (data) => {
    console.log(data)
    // const seriyaCombined = data.seriyaNo.toUpperCase() + data.seriyaValue;
    const params = {
      pin: data.fin,
      name: data.name ,
      surname: data.surname,
    };
    setLoading(true);

    axios
      .get(resultUrl, { params })
      .then((response) => {
        setShowInfo(true);
        setLoading(false);
        // setInfo(response.data.data.info);
        setResult(response.data.data.info);
      })
      .catch((error) => {
        console.error("API xetasi:", error.response.data.message);
        setLoading(false);
        notification.error({
          description: error.response.data.message,
          placement: "topRight", // Notification yerleşimi
        });
      });
  };
  console.log(result);

  const titles = [
    { key: "student_name", label: "Ad" },
    { key: "student_surname", label: "Soyad" },
    { key: "speciality", label: "İxtisas" },
    { key: "exam_date", label: "İmtahan tarixi" },
    { key: "exam_place", label: "İmtahan yeri" },
    { key: "exam_address", label: "İmtahan ünvanı" },
  ];

  return (
    <div className="form-page">
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        {/* <h1 className="exam-title">
          İmtahan tarixini və keçiriləcəyi yeri öyrən
        </h1> */}
        <h1 className="exam-title">Müəllimlərin işə qəbulu</h1>
      </div>
      <div className="form">
        <form onSubmit={handleSubmit(onSubmit)} className="form-container">
          <h2 className="title">Namizəd məlumatları</h2>
          <div className="form-group">
            <label htmlFor="finKod">Fin kod</label>
            <input id="finKod"  {...register("fin", {
          required: "Daxil edilməlidir",
        })}  className="fin" />
        {errors.fin && <p className="error-message">{errors.fin.message}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="name">Ad</label>
            <input id="name"  {...register("name", {
          required: "Daxil edilməlidir",
        })} className="fin" />
        {errors.name && <p className="error-message">{errors.name.message}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="surname">Soyad</label>
            <input id="surname"  {...register("surname", {
          required: "Daxil edilməlidir",
        })}  className="fin" />
        {errors.surname && <p className="error-message">{errors.surname.message}</p>}
          </div>

          {/* <div className="form-group">
            <label htmlFor="seriyaNo">Seriya №</label>
            <div className="seriya-container">
              <select id="seriyaNo" {...register("seriyaNo")}>
                {!isForeignPerson && (
                  <>
                    <option value="AA">AA</option>
                    <option value="AZE">AZE</option>
                  </>
                )}
                {isForeignPerson && (
                  <>
                    <option value="MYİ">MYİ</option>
                    <option value="DYİ">DYİ</option>
                  </>
                )}
              </select>
              <input id="seriyaValue" {...register("seriyaValue")} />
            </div>
          </div> */}
          {/* <div className="form-group">
            <label className="foreign">
              <input
                type="checkbox"
                checked={isForeignPerson}
                onChange={handleCheckboxChange}
              />
              Xarici vətəndaş
            </label>
          </div> */}

          <div className="btn">
            <button type="submit">Axtar</button>
          </div>
        </form>
      </div>
      {loading && (
        <div className="loading-spinner">
          <Spin spinning={loading} size="large" />
        </div>
      )}
      {showInfo && (
        <div className="result">
          <div className="result-container">
            <h2 className="title">Axtarış nəticəsi</h2>
            <div className="">
              {/* {titles.map(({ key, label }) => (
                <div className="result-info" key={key}>
                  <p>{label}</p>
                  <p className="content">{info[key]}</p>
                </div>
              ))} */}
              <div className="result-info">
                <p  dangerouslySetInnerHTML={{ __html: result }}></p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
